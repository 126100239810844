<script setup lang="ts">
import type { EditorialTextModuleProps } from './EditorialTextModule.props'

const props = defineProps<EditorialTextModuleProps>()

const { isGiorgioArmaniWebsite } = useWebsite()

const logoSizeOptions = {
  s: 'w-40 h-full',
  m: 'lg:w-full w-52 lg:max-w-[244px] h-full',
  l: 'lg:w-full w-64 lg:max-w-[400px] h-full',
}

const logoSignatureSizeOptions = {
  s: 'w-40',
  m: 'w-60',
  l: 'w-80',
}

useState('hasHeroBanner', () => props.firstPageContent)

const { deferredTransparencyState } = useDefaultHeaderConfig()
</script>

<template>
  <div
    class="py-4xl gap-y-xxl px-sm md:px-4xl relative grid grid-cols-12"
    :class="[
      bgColor ? `bg-neutral-${bgColor}` : 'bg-background-secondary',
      textColor ? `text-editorial-${textColor}` : 'text-text-primary',
      firstPageContent
        ? deferredTransparencyState.active
          ? 'editorial-first-content-header-transparent'
          : 'editorial-first-content'
        : 'h-full',
    ]"
  >
    <!-- Background image -->
    <div
      v-if="backgroundMedia"
      class="background-image-container absolute inset-0 h-full w-full"
    >
      <div class="h-full w-full">
        <UIMedia
          v-bind="backgroundMedia"
          class="h-full w-full object-cover object-top"
          :class="{ 'brightness-[60%]': hasDarkOverlay }"
        />
      </div>
    </div>

    <div
      class="content-container gap-md z-10 col-span-full my-auto flex flex-col"
      :class="fullWidth ? 'lg-custom:col-span-6 lg-custom:col-start-4' : ''"
    >
      <div
        v-if="logoBrands && logoBrands.top"
        class="gap-xs md:gap-md flex items-center justify-center"
      >
        <component
          :is="megaLogoMap[singleLogo]"
          v-for="(singleLogo, index) in logoBrands.top.slice(0, 2)"
          :key="`${singleLogo}-${index}`"
          :class="logoSizeOptions[logoBrands.size ?? 'm']"
        />
      </div>
      <UtilsMarkdown
        v-if="title"
        :content="title"
        is-preprocessed
        container="span"
        :class="[
          'text-center uppercase',
          isGiorgioArmaniWebsite
            ? 'text-light-4 md:text-light-3'
            : 'text-book-3',
        ]"
      />
      <UtilsMarkdown
        v-if="subtitle"
        :content="subtitle"
        is-preprocessed
        container="span"
        class="text-book-5 md:text-book-4 text-center uppercase"
      />
      <UtilsMarkdown
        v-if="description"
        :content="description"
        container="span"
        class="text-light-5 text-center"
      />
      <UIMedia
        v-if="logoSignature?.media.srcImage"
        :media="logoSignature.media"
        class="mx-auto"
        :class="logoSignatureSizeOptions[logoSignature.size]"
      />
      <div
        v-if="ctaLinks"
        class="gap-md [&>*]:!text-link-5 flex flex-wrap justify-center"
      >
        <UILink
          v-for="(link, index) in ctaLinks.slice(0, 4)"
          :key="`${link.label}-${index}`"
          v-bind="link"
        />
      </div>
      <div
        v-if="logoBrands && logoBrands.bottom"
        class="gap-xs md:gap-md flex items-center justify-center"
      >
        <component
          :is="megaLogoMap[singleLogo]"
          v-for="(singleLogo, index) in logoBrands.bottom.slice(0, 2)"
          :key="`${singleLogo}-${index}`"
          :class="logoSizeOptions[logoBrands.size ?? 'm']"
        />
      </div>
    </div>

    <div
      v-if="ctaButtons"
      class="cta-buttons-container z-10 col-span-full my-auto"
    >
      <div class="gap-md flex flex-wrap justify-center">
        <UILink
          v-for="(button, index) in ctaButtons.slice(0, 4)"
          :key="`${button.label}-${index}`"
          anatomy="primary"
          v-bind="button"
        />
      </div>
    </div>
  </div>
</template>
